<template>
  <div
    ref="customSelect"
    class="customSelect"
    :style="{ minWidth: renderWidth }"
    v-if="options"
  >
    <div class="selectBox" @click.stop="onOpen">
      <p v-if="renderHint" class="option-name">{{ hint }}</p>
      <p v-else class="option-name">{{ optionsArr[this.selectedItem] }}</p>

      <!-- <img
        :class="{ rotate: showOptions }"
        src="@/assets/icons/down_arrow.svg"
        class="arrow" -->
        <div :class="{ rotate: showOptions }" class="arrow">
          <arrowdown></arrowdown>
        </div>

    </div>
    <div class="dropdown" :class="{ animate: showOptions }">
      <div
      v-if="hint"
      class="option-container"
      >
      <div class="option option-name hint">{{hint}}</div>
      </div>
      <div
        class="option-container"
        v-for="(item, index) in optionsArr"
        :key="index"
        @click.stop="onChange(index)"
      >
        <div class="option option-name">{{ item }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    options: {
      type: Array,
      required: true,
    },
    selected: {
      type: Number,
      default: 0,
    },
    displayKey: String,
    width: {
      type: String,
      default: 100,
    },
    hint:{
      type:String,
      required: false,
      default: null,
    }
  },
  data() {
    return {
      selectedItem: null,
      optionsArr: null,
      showOptions: false,
      renderWidth: "100px",
      renderHint: false,
    };
  },
  created() {
   
    this.createOptions();
    if (this.width === "max") {
      this.renderWidth = "100%";
    } else if (
      this.width !== null &&
      this.width !== undefined &&
      !isNaN(this.width)
    ) {
      let renderW = `${this.width}px`;
      this.renderWidth = renderW;
    }

    if(this.hint){
      this.renderHint = true;
    }
    else{
      this.renderHint = false;
    }
  },
  mounted() {
    //detect outside click and hide the dropdown menu
    //please note this will not get triggered when menu items itself are clicked
    //all click events inside this component have @click.stop defined which will prevent this listener from running
    document.addEventListener("click", this.hideDropDownList);
  },
  destroyed() {
    //remove event listener as it is no longer required
    document.removeEventListener("click", this.hideDropDownList);
  },
  events: ["onSelectChange"],
  methods: {
    hideDropDownList() {
      this.animate(false);
    },
    createOptions() {
      if (this.options === null || this.options === undefined) {
        return;
      }
      this.selectedItem = this.selected;
      let optionsObj = this.options;

      if (this.displayKey !== null && this.displayKey !== undefined) {
        let slugArr = [];
        for (let i = 0; i < optionsObj.length; i++) {
          let item = optionsObj[i];
          let displayName = "";
          if (this.displayKey.includes(".")) {
            let [key1, key2] = this.displayKey.split(".");
            displayName = item[key1][key2].toString();
          } else {
            displayName = item[this.displayKey].toString();
          }
          slugArr.push(displayName);
        }
        this.optionsArr = slugArr;
      } else {
        this.optionsArr = this.options;
      }
    },

    onChange(index) {
      this.renderHint = false;
      //hide options list and animate here
      this.animate(false);
      //update state and send data to parent
      let item = this.optionsArr[index];
      if (this.selectedItem === index) {
        return;
      }
      this.selectedItem = index;
      let propsItem = this.options[index];
      let payload = {
        selectedIndex: index,
        selectedItem: propsItem,
        itemName: item,
      };
      this.$emit("onSelectChange", payload);
    },
    animate(show) {
      this.showOptions = show;
    },
    onOpen() {
      this.animate(!this.showOptions);
    },
    onClose() {
      this.animate(false);
    },
  },
  components: {
    arrowdown: () => import(/* webpackChunkName: "deleteicon" */ "../SvgImages/DownArrow.vue"),
    
     },
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
.customSelect {
  position: relative;
  display: inline-block;
}
.dropdown {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
  z-index: 2500;
}
.option-container {
  background-color:$clr-saina-light-dark-bg; 
  padding: 5px 0px;
}

.option {
  color: $clr-saina-light-dark-txt;
  cursor: pointer;
  font-size: 14px;
  padding: 8px 10px;
}
.option:hover {
  background: #999;
  border-radius: 5px;
  color: #151618;
}

.selectBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $clr-saina-light-dark-txt;
  background-color: $clr-saina-light-dark-bg;
  color: $clr-saina-light-dark-txt;
  font-size: 14px;
  padding: 6px 10px;
  cursor: pointer;
  border-radius: 5px;
  gap: 10px;
}
.animate {
  opacity: 1;
  visibility: visible;
}

.arrow {
  width: 14px;
  height: 14px;
  transform: rotate(0deg);
  transition: transform 0.3s ease-in-out;
}

.rotate {
  transform: rotate(180deg);
}

.option-name {
  text-overflow: ellipsis;
}
</style>
